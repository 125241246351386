<template>
  <div class="mb-3">
    <cp-table
      ref="cpTable"
      :get-data-action="isWhitelist ? 'securitizeId/getWhitelistedWallets' : 'securitizeId/getBlacklistedWallets'"
      :fields="tableFields(isWhitelist)"
      :search-value="searchQueryParam"
      :data-parser="parseData"
      default-sort-direction="desc"
      without-search
      @tableDataUpdated="onTableDataUpdated"
    >
      <template slot="tableHeader">
        <span class="headers-wrapper">
          <div class="d-flex justify-content-end p-4">
            <b-button
              size="sm"
              variant="primary"
              @click="addWalletToList"
            >
              {{ `${ isWhitelist ? 'Whitelist' : 'Blacklist'} wallet` }}
            </b-button>
          </div>
        </span>
      </template>
      <template
        slot="blockchain"
        slot-scope="{ rowData }"
      >
        <span class="text-capitalize">
          {{ getBlockchain(rowData.item.blockchain) }}
        </span>
      </template>
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.createdAt | dateFilter }}
        </span>
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            variant="primary"
            @click="removeFromList(rowData.item)"
          >
            Remove
          </cp-button>
        </div>
      </template>
    </cp-table>
    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="`Remove from ${ isWhitelist ? 'whitelist' : 'blacklist'}`"
      class="bg-light py-3 px-4 "
      body-class="text-center"
      :item="walletToRemove"
      @onOk="removeWallet"
    >
      <p class="mb-2">
        Do you want to remove this wallet from the {{ isWhitelist ? 'whitelist' : 'blacklist' }}?
      </p>
      <p class="text-muted text-capitalize">
        {{ getBlockchain(walletToRemove.blockchain) }}: {{ walletToRemove.address }}
      </p>
    </cp-confirm-modal>
    <add-wallet-to-list
      ref="addWalletToListModal"
      :is-whitelist="isWhitelist"
      @walletAdded="onWalletAdded"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CpTable from '@/components/shared/cp-table/index';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpTimeConverter from '@/mixins/time-converter';
import tableFields from './options';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal';
import AddWalletToList from '@/pages/securitize-id/components/wallets/add-wallet-to-list-modal';

export default {
  name: 'WalletsTable',
  components: {
    AddWalletToList,
    CpTable,
    CpButton,
    CpConfirmModal,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    isWhitelist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableFields,
      totalTableData: [],
      searchQueryParam: this.$route.query.search || undefined,
      isEmptyList: false,
      walletToRemove: {},
    };
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    ...mapActions('securitizeId', ['removeBlacklistedWallet', 'removeWhitelistedWallet']),
    parseData({ items }) {
      return items;
    },
    onTableDataUpdated({ items, totalItems }) {
      this.totalTableData = items;
      this.isEmptyList = totalItems === 0;
    },
    removeFromList(item) {
      this.walletToRemove = item;
      this.$refs.cpConfirmModal.show();
    },
    async removeWallet() {
      if (this.isWhitelist) await this.removeWhitelistedWallet(this.walletToRemove.id);
      else await this.removeBlacklistedWallet(this.walletToRemove.id);
      this.$refs.cpTable._updateTableData();
    },
    onWalletAdded() {
      this.$refs.cpTable._updateTableData();
    },
    addWalletToList() {
      this.$refs.addWalletToListModal.show();
    },
    getBlockchain(blockchain) {
      return blockchain === 'ETH' ? 'ethereum' : blockchain;
    },
  },
};
</script>
