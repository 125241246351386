<template>
  <cp-general-modal
    ref="addWallet"
    :title="`${ isWhitelist ? 'Whitelist' : 'Blacklist'} wallet`"
    :ok-disabled="disabled"
    @onOk="addWallet"
    @onCancel="onClose"
  >
    <label
      class="form-label"
    >
      Blockchain
    </label>
    <b-select
      id="blockchain"
      v-model="model.blockchain"
      class="mb-2"
      :options="['ETH', 'algorand']"
    />
    <cp-input
      v-model="model.address"
      name="address"
      label="Address"
      :validate="`required|wallet_address:${model.blockchain}`"
    />
    <b-alert
      :show="isError"
      variant="danger mt-3 mb-0"
    >
      {{ errorMessageParsed }}
    </b-alert>
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';

import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'AddWalletToList',
  components: {
    CpInput,
    CpGeneralModal,
  },
  props: {
    isWhitelist: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      model: {},
      loading: false,
      isError: false,
      errorMessage: null,
    };
  },
  computed: {
    disabled() {
      return this.loading || !this.model.blockchain || !this.model.address;
    },
    errorMessageParsed() {
      return this.errorMessage
        || `An error occurred while ${this.isWhitelist ? 'whitelisting' : 'blacklisting'} the wallet`;
    },
  },
  methods: {
    ...mapActions('securitizeId', ['whitelistWallet', 'blacklistWallet']),
    show() {
      this.$refs.addWallet.show();
    },
    async addWallet() {
      try {
        this.isError = null;
        this.errorMessage = null;
        this.loading = true;
        if (this.isWhitelist) await this.whitelistWallet(this.model);
        else await this.blacklistWallet(this.model);
        this.$emit('walletAdded');
        this.model = {};
        this.$refs.addWallet.hide();
      } catch (err) {
        this.isError = true;
        if (err.response.status === 400) this.errorMessage = err.response.data.message;
      } finally {
        this.loading = false;
      }
    },
    onClose() {
      this.model = {};
      this.loading = false;
      this.isError = false;
    },
  },
};
</script>
