export default isWhitelist => [
  {
    key: 'address', label: isWhitelist ? 'Whitelist' : 'Blacklist', sortable: false, tdClass: 'align-middle text-one-line',
  },
  {
    key: 'blockchain', label: 'Blockchain', sortable: false, tdClass: 'align-middle text-one-line',
  },
  {
    key: 'createdAt', label: 'Date', sortable: false, tdClass: 'align-middle text-one-line',
  },
  { key: 'actions', label: '', tdClass: 'text-nowrap d-inline float-right' },
];
