<template>
  <div>
    <div class="header-block mb-3">
      <h4 class="font-weight-bold mb-0">
        <i class="ion ion-ios-globe mr-2" />
        Wallets
      </h4>
    </div>
    <div class="d-flex align-items-center justify-content-end mb-3">
      <cp-input
        v-model="q"
        class="w-25"
        placeholder="Search by address"
      />
    </div>
    <div>
      <wallets-table />
      <wallets-table is-whitelist />
    </div>
  </div>
</template>

<script>
import WalletsTable from '@/pages/securitize-id/components/wallets/wallets-table';
import CpInput from '@/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'SecuritizeIdTranslations',
  metaInfo: {
    title: 'Wallets',
  },
  components: {
    CpInput,
    WalletsTable,
  },
  data() {
    return {
      q: '',
    };
  },
  watch: {
    q(newValue) {
      this.$router.push({ query: { search: newValue } });
    },
  },
};
</script>
